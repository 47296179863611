import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getFirestore, doc, setDoc, Timestamp } from "firebase/firestore";

const storage = getStorage();
const db = getFirestore();

async function uploadBannerImage(file, bannerId) {
  try {
    // Upload the image to Firebase Storage
    const storageRef = ref(storage, `banners/${bannerId}-${Date.now()}`);
    const snapshot = await uploadBytes(storageRef, file);

    // Get the download URL
    const downloadURL = await getDownloadURL(snapshot.ref);

    // Save URL and other details to Firestore - using 'banners' collection
    await setDoc(doc(db, "banners", bannerId), {
      name: bannerId,
      image_url: downloadURL,
      created_at: Timestamp.now(),
      updated_at: Timestamp.now(),
    });

    console.log("Banner uploaded successfully!");
    return true;
  } catch (error) {
    console.error("Upload failed:", error);
    throw error;
  }
}

export default uploadBannerImage;
