import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Sidebar from "../../Components/Sidebar/Sidebar";
import Navbar from "../../Components/Navbar/Navbar";
import "../../Reusable Styling/AddItem.sass";
import toast from "react-hot-toast";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import db from "../../firebase";

const UpdateHelper = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    helper_name: "",
    helper_contact: "",
    helper_category: "",
    shop_details: "",
  });

  useEffect(() => {
    const fetchHelper = async () => {
      try {
        const docRef = doc(db, "helpers", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setFormData(docSnap.data());
        } else {
          toast.error("Helper not found");
          navigate("/helpers");
        }
      } catch (err) {
        console.error(err);
        toast.error("Failed to fetch helper data");
      }
    };

    fetchHelper();
  }, [id, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !formData.helper_name ||
      !formData.helper_contact ||
      !formData.helper_category
    ) {
      toast.error("Please fill all required fields");
      return;
    }

    try {
      const docRef = doc(db, "helpers", id);
      await updateDoc(docRef, formData);
      toast.success("Helper updated successfully");
      navigate("/helpers");
    } catch (error) {
      console.error("Error updating helper:", error);
      toast.error("Failed to update helper");
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <main className="dashboard_container_main">
      <Sidebar />
      <div className="dashboard_container_right_panel">
        <Navbar />
        <div className="add_item_title_div">
          <h6>Update Helper</h6>
        </div>
        <div className="add_item_container">
          <div className="add_user_item_div_wrapper">
            <div className="add_user_item_div">
              <div className="form_div">
                <form onSubmit={handleSubmit}>
                  <div className="form_input_div">
                    <div className="form_input">
                      <label>Helper Name</label>
                      <input
                        required
                        type="text"
                        name="helper_name"
                        value={formData.helper_name}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form_input">
                      <label>Contact</label>
                      <input
                        required
                        type="text"
                        name="helper_contact"
                        value={formData.helper_contact}
                        onChange={handleChange}
                      />
                    </div>
                    <div
                      className="form_input"
                      style={{ marginLeft: "-110px" }}
                    >
                      <label>Category</label>
                      <select
                        required
                        name="helper_category"
                        value={formData.helper_category}
                        onChange={handleChange}
                      >
                        <option value="">Select Category</option>
                        <option value="Delivery">Delivery</option>
                        <option value="Maintenance">Maintenance</option>
                        <option value="Support">Support</option>
                      </select>
                    </div>
                    <div className="form_input" style={{ marginLeft: "-60px" }}>
                      <label>Shop Details</label>
                      <textarea
                        name="shop_details"
                        value={formData.shop_details}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <button type="submit">Update</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default UpdateHelper;
