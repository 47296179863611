import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Sidebar from "../../Components/Sidebar/Sidebar";
import Navbar from "../../Components/Navbar/Navbar";
import "../../App.sass";
import {
  collection,
  query,
  getDocs,
  where,
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import db from "../../firebase";
import "./invoice.css";
import html2PDF from "jspdf-html2canvas";
import { ToWords } from "to-words"; // Ensure the correct import

const Invoice = () => {
  const { id } = useParams();
  const [rows, setRows] = useState([]);
  const [tot, setTot] = useState(0);
  const [customer, setCustomer] = useState({});
  const [totalTaxableAmount, setTotalTaxableAmount] = useState(0);
  const [secondTableData, setSecondTableData] = useState([]);
  const [totalAmountInWords, setTotalAmountInWords] = useState("");
  const [totalTaxAmountInWords, setTotalTaxAmountInWords] = useState("");
  const [invoiceSerial, setInvoiceSerial] = useState(""); // Added state for invoice serial
  const toWords = new ToWords();

  const downloadPdf = async () => {
    try {
      const downloadButton = document.querySelector(".download-button");
      downloadButton.style.display = "none";
      await new Promise((resolve) => setTimeout(resolve, 100));
      const page = document.querySelector(".toinvoice");
      html2PDF(page, {
        jsPDF: { format: "a4" },
        imageType: "image/jpeg",
        output: `${id}.pdf`,
      });
      downloadButton.style.display = "block";
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  const getOrder = async () => {
    const a = [];
    const q = query(collection(db, "orders"), where("id", "==", id));
    const queryt = await getDocs(q);
    queryt.forEach((doc) => a.push(doc.data()));
    return a[0];
  };

  const getCustomer = async (customerId) => {
    const a = [];
    const q = query(collection(db, "customers"), where("id", "==", customerId));
    const queryt = await getDocs(q);
    queryt.forEach((doc) => a.push(doc.data()));
    return a[0];
  };

  const getSubproduct = async (subproductId) => {
    const a = [];
    const q = query(
      collection(db, "sub-product"),
      where("id", "==", subproductId)
    );
    const queryt = await getDocs(q);
    queryt.forEach((doc) => a.push(doc.data()));
    return a[0];
  };

  const getNextInvoiceCount = async () => {
    const counterRef = doc(db, "counters", "invoiceCounter");

    try {
      const counterDoc = await getDoc(counterRef);

      if (!counterDoc.exists()) {
        // Initialize counter if it doesn't exist
        await setDoc(counterRef, { count: 1 });
        return 1;
      }

      const currentCount = counterDoc.data().count;
      const nextCount = currentCount + 1;

      // Update the counter in Firebase
      await updateDoc(counterRef, { count: nextCount });

      return nextCount;
    } catch (error) {
      console.error("Error getting invoice number:", error);
      return null;
    }
  };

  const generateInvoiceSerial = async () => {
    const currentYear = new Date().getFullYear().toString().slice(-2); // Get last 2 digits of year
    const prefix = "KT";
    const count = await getNextInvoiceCount();
    // Pad the count with zeros to ensure 4 digits (e.g., 0001)
    const paddedCount = count.toString().padStart(4, "0");
    const invoiceSerial = `${prefix}${currentYear}${paddedCount}`;
    setInvoiceSerial(invoiceSerial);
  };

  const maketable = async () => {
    const td = [];
    const secondTable = [];
    const order = await getOrder();
    if (!order) return;

    try {
      const customerData = await getCustomer(order.customer);
      setCustomer(customerData);

      const gstTotals = {};
      let totalAmount = 0;
      for (const mainProduct of order.products) {
        const subProduct = await getSubproduct(mainProduct.subproductId);
        if (!subProduct) continue;

        const total =
          parseFloat(mainProduct.quantity) * parseFloat(subProduct.sellCost);
        const gst = parseInt(subProduct.gst);

        gstTotals[gst] = (gstTotals[gst] || 0) + total;
        totalAmount += total;
        const productDetails = `${subProduct.productName} - ${subProduct.subCategory}`;
        const date = order.date.toDate();
        const dateString = date.toLocaleDateString();

        const row = {
          customer: order.customername,
          pname: productDetails,
          sellcost: subProduct.sellCost,
          gst: gst,
          quantity: mainProduct.quantity,
          total: total,
          id: order.id,
          date: dateString,
        };
        td.push(row);
      }
      setTot(totalAmount);

      let totalTaxableAmount = 0;
      for (const gstValue in gstTotals) {
        const taxableValue = parseFloat(gstTotals[gstValue]);
        const centralTaxRate = gstValue / 2;
        const stateTaxRate = gstValue / 2;
        const centralTaxAmount = parseFloat(
          (taxableValue * centralTaxRate) / 100
        );
        const stateTaxAmount = parseFloat((taxableValue * stateTaxRate) / 100);
        const totalTaxAmount =
          parseFloat(centralTaxAmount) + parseFloat(stateTaxAmount);
        totalTaxableAmount += totalTaxAmount;

        const row = {
          taxableValue: taxableValue.toFixed(2),
          centralTaxRate: centralTaxRate.toFixed(2),
          centralTaxAmount: centralTaxAmount.toFixed(2),
          stateTaxRate: stateTaxRate.toFixed(2),
          stateTaxAmount: stateTaxAmount.toFixed(2),
          totalTaxAmount: totalTaxAmount.toFixed(2),
        };
        secondTable.push(row);
      }

      setRows(td);
      setSecondTableData(secondTable);
      setTotalTaxableAmount(totalTaxableAmount.toFixed(2));
      setTotalAmountInWords(
        toWords.convert(Math.round(totalAmount + totalTaxableAmount))
      );
      setTotalTaxAmountInWords(toWords.convert(Math.round(totalTaxableAmount)));

      // Generate invoice serial
      await generateInvoiceSerial();
    } catch (error) {
      console.error("Error processing table data:", error);
    }
  };

  useEffect(() => {
    maketable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]); // Added id as a dependency to the effect

  useEffect(() => {
    document.title = "Invoice | Admin Dashboard";
  });

  return (
    <>
      {rows.length === 0 ? (
        <h2>Generating Invoice</h2>
      ) : (
        <main className="dashboard_container_main">
          <Sidebar />
          <div className="dashboard_container_right_panel">
            <Navbar />
            <div className="toinvoice">
              <div className="container">
                <div className="invoice-title">
                  <h3 className="mb-1 text-muted">VARJU PRIVATE LIMITED</h3>
                  <div className="text-muted">
                    <p className="mb-1">SR NO 648 UPPER KONDWA PUNE 411037</p>
                    <p className="mb-1">STATE: MAHARASHTRA</p>
                    <p className="mb-1">GSTIN: 27CKVPM1262R1Z6</p>
                    <p>Phone: +91 7020971990 / +91 9422752305</p>
                  </div>
                </div>
                <hr className="my-4" />
                <div className="flex-container">
                  <div className="flex-item">
                    <div className="text-muted">
                      <h5 className="font-size-16 mb-3">Billed To:</h5>
                      <h4 className="bold">{customer?.shopname}</h4>
                      <p className="mb-1">Owner: {customer?.ownername}</p>
                      <p className="mb-1">Address: {customer?.address}</p>
                      <p className="mb-1">GST NO: {customer?.gst}</p>
                      <p>Phone: {customer?.contact}</p>
                    </div>
                  </div>
                  <div className="flex-item text-end">
                    <div className="text-muted">
                      <h5 className="font-size-16 mb-3">Order Details:</h5>
                      <p className="mb-1">
                        <span className="fw-semibold">Order Date:</span>{" "}
                        {rows[0]?.date}
                      </p>
                      <p className="mb-1">
                        <span className="fw-semibold">Order ID:</span>{" "}
                        {rows[0]?.id}
                      </p>
                      <p className="mb-1">
                        <span className="fw-semibold">Invoice Serial:</span>{" "}
                        {invoiceSerial}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table table-nowrap">
                    <thead>
                      <tr>
                        <th>S/N</th>
                        <th>Product</th>
                        <th>GST %</th>
                        <th>Qty</th>
                        <th>Rate</th>
                        <th>GST</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rows.map((row, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td className="bold">{row.pname}</td>
                          <td>{row.gst}</td>
                          <td className="bold">{row.quantity}</td>
                          <td>{row.sellcost}</td>
                          <td>
                            {parseFloat((row.sellcost * row.gst) / 100).toFixed(
                              2
                            )}
                          </td>
                          <td className="bold text-end">
                            {parseFloat(
                              row.total * (1 + row.gst / 100)
                            ).toFixed(2)}
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan="6" className="border-0 text-end">
                          <strong>Total:</strong>
                        </td>
                        <td className="border-0 text-end">
                          <strong>
                            ₹{" "}
                            {Math.round(
                              parseInt(tot) + parseInt(totalTaxableAmount)
                            )}
                            .00
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="total-section">
                    <h5>
                      Amount Chargeable (in words): {totalAmountInWords} Only
                    </h5>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table table-nowrap">
                    <thead>
                      <tr>
                        <th>S/N</th>
                        <th>Taxable Value</th>
                        <th>CGST Rate</th>
                        <th>CGST Amount</th>
                        <th>SGST Rate</th>
                        <th>SGST Amount</th>
                        <th>Total Tax</th>
                      </tr>
                    </thead>
                    <tbody>
                      {secondTableData.map((row, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{row.taxableValue}</td>
                          <td>{row.centralTaxRate}%</td>
                          <td>{row.centralTaxAmount}</td>
                          <td>{row.stateTaxRate}%</td>
                          <td>{row.stateTaxAmount}</td>
                          <td className="bold text-end">
                            {row.totalTaxAmount}
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan="6" className="border-0 text-end">
                          <strong>Total:</strong>
                        </td>
                        <td className="border-0 text-end">
                          <strong>₹ {totalTaxableAmount}</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="total-section">
                  <h5>Tax Amount (in words): {totalTaxAmountInWords} Only</h5>
                </div>
              </div>
              <div className="float-end">
                <button
                  onClick={downloadPdf}
                  className="btn btn-primary w-md download-button"
                >
                  Download
                </button>
              </div>
            </div>
          </div>
        </main>
      )}
    </>
  );
};

export default Invoice;
