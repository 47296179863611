import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { ProfileContext } from "../../App";
import Sidebar from "../../Components/Sidebar/Sidebar";
import Navbar from "../../Components/Navbar/Navbar";
import ListInTable from "../../Reusable Components/DataTable";
import { unitListTableColumns } from "./UnitData";
import "../../App.sass";
import {
  collection,
  query,
  getDocs,
  deleteDoc,
  doc,
  where,
} from "firebase/firestore";
import db from "../../firebase";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

const Units = () => {
  const { userName } = useContext(ProfileContext);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const q = query(collection(db, "customers"));
        const querySnapshot = await getDocs(q);
        const rowsData = querySnapshot.docs.map((doc) => doc.data());
        setRows(rowsData);
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    document.title = "Customers | Admin Dashboard";
  });

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => (
        <div className="cell_action_div">
          <Link
            to={`/customer/${params.row.id}`}
            style={{ textDecoration: "none", color: "unset" }}
            className="view_btn"
          >
            View
          </Link>
          <Link
            to={`/customer/${params.row.id}/orders`}
            style={{ textDecoration: "none", color: "unset" }}
            className="view_btn"
          >
            Orders
          </Link>
        </div>
      ),
    },
  ];

  return (
    <>
      <main className="dashboard_container_main">
        <Sidebar />
        <div className="dashboard_container_right_panel">
          <Navbar />
          <div className="products_list_container">
            <div className="products_list_container_title">
              <h4
                className="p-2 mb-0"
                style={{
                  fontWeight: 700,
                  margin: "0.5rem 0 0 0",
                  padding: "0 0.5rem",
                }}
              >
                Customers handled by Admin | {userName}
              </h4>
            </div>
            <ListInTable
              rows={rows}
              columns={unitListTableColumns.concat(actionColumn)}
              height={400}
            />
          </div>
        </div>
      </main>
    </>
  );
};

export const ProductListContainer = styled.div`
  /* Resetting MUI table color props */
  p,
  .css-rtrcn9-MuiTablePagination-root .MuiTablePagination-selectLabel,
  div.MuiTablePagination-actions > button button {
    color: inherit;
  }

  .MuiToolbar-root {
    color: inherit;
  }
  /* END */
`;

export default Units;
