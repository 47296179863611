import React, { useState, useEffect } from "react";
import { Grid, Paper, TextField, Button } from "@mui/material";
import { collection, getDocs } from "firebase/firestore";
import db from "../../firebase";
import ListInTable from "../../Reusable Components/DataTable";
import { format } from "date-fns";

const AccountingReport = () => {
  const [orders, setOrders] = useState([]);
  const [filters, setFilters] = useState({
    fromDate: "",
    toDate: "",
  });

  const columns = [
    {
      field: "date",
      headerName: "Date",
      width: 150,
      valueFormatter: (params) => format(new Date(params.value), "dd/MM/yyyy"),
    },
    {
      field: "monthYear",
      headerName: "Month Year",
      width: 150,
      valueFormatter: (params) => format(new Date(params.value), "MMMM yyyy"),
    },
    {
      field: "sellCount",
      headerName: "Total Sell Count",
      width: 150,
    },
    {
      field: "totalAmount",
      headerName: "Total Sell Amount",
      width: 200,
      valueFormatter: (params) => `₹${params.value.toFixed(2)}`,
    },
  ];

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const ordersSnapshot = await getDocs(collection(db, "orders"));

        // Process and group orders by date
        const ordersByDate = ordersSnapshot.docs.reduce((acc, doc) => {
          const orderData = doc.data();
          const date = orderData.date.toDate();
          const dateStr = format(date, "yyyy-MM-dd");

          if (!acc[dateStr]) {
            acc[dateStr] = {
              date: date,
              monthYear: date,
              sellCount: 0,
              totalAmount: 0,
            };
          }

          acc[dateStr].sellCount += 1;
          acc[dateStr].totalAmount += parseFloat(orderData.total || 0);

          return acc;
        }, {});

        // Convert to array and apply filters
        let processedOrders = Object.values(ordersByDate);

        if (filters.fromDate || filters.toDate) {
          processedOrders = processedOrders.filter((order) => {
            const orderDate = order.date;
            const fromDate = filters.fromDate
              ? new Date(filters.fromDate)
              : null;
            const toDate = filters.toDate ? new Date(filters.toDate) : null;

            return (
              (!fromDate || orderDate >= fromDate) &&
              (!toDate || orderDate <= toDate)
            );
          });
        }

        // Add unique IDs for the data grid
        processedOrders = processedOrders.map((order, index) => ({
          ...order,
          id: index.toString(),
        }));

        setOrders(processedOrders);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [filters]);

  const exportToExcel = () => {
    const csvContent = [
      "Date,Month Year,Total Sell Count,Total Sell Amount",
      ...orders.map(
        (order) =>
          `${format(order.date, "dd/MM/yyyy")},${format(
            order.monthYear,
            "MMMM yyyy"
          )},${order.sellCount},₹${order.totalAmount.toFixed(2)}`
      ),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "accounting_report.csv";
    link.click();
  };

  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <h2 align="center" style={{ margin: "0 0 20px 0" }}>
        Accounting Report
      </h2>

      <Paper sx={{ p: 2, mb: 2 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              type="date"
              name="fromDate"
              label="From Date"
              value={filters.fromDate}
              onChange={handleFilterChange}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              type="date"
              name="toDate"
              label="To Date"
              value={filters.toDate}
              onChange={handleFilterChange}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              variant="contained"
              onClick={exportToExcel}
              style={{ backgroundColor: "#027f89" }}
            >
              Export to Excel
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <ListInTable rows={orders} columns={columns} height={500} />
    </div>
  );
};

export default AccountingReport;
