import React, { useState } from 'react';
import { Box, MenuItem, Select, FormControl, InputLabel, Typography } from '@mui/material';
import AccountingReport from './AccountingReport';
import GeneralReport from './GeneralReport';
import GSTReport from './GSTReport';
import Navbar from '../../Components/Navbar/Navbar';

const Reports = () => {
  const [selectedReport, setSelectedReport] = useState('');

  const handleReportChange = (event) => {
    setSelectedReport(event.target.value);
  };

  return (
    <>
      <Navbar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "start",
          height: "100vh",
          marginTop: "50px",
        }}
      >
        {selectedReport === "" ? (
          <>
          <Typography variant="h5" sx={{ mb: 2 }}>Select a report to view:</Typography>
          <FormControl sx={{ m: 1, minWidth: 400 }}>
            <InputLabel id="report-select-label">Select Report</InputLabel>
            <Select
              labelId="report-select-label"
              id="report-select"
              value={selectedReport}
              onChange={handleReportChange}
            >
              <MenuItem value="">Select Report</MenuItem>
              <MenuItem value="accounting">Accounting Report</MenuItem>
              <MenuItem value="general">General Report</MenuItem>
              <MenuItem value="gst">GST Report</MenuItem>
            </Select>
          </FormControl>
        </>
      ) : (
        <>
          {selectedReport === 'accounting' && <AccountingReport />}
          {selectedReport === 'general' && <GeneralReport />}
          {selectedReport === 'gst' && <GSTReport />}
        </>
      )}
      </Box>
    </>
  );
};

export default Reports;