import React, { useState, useEffect } from "react";
import { TableContainer, Paper, TextField, Button, Grid } from "@mui/material";
import { collection, getDocs } from "firebase/firestore";
import db from "../../firebase";
import ListInTable from "../../Reusable Components/DataTable";
import { format } from "date-fns"; // Import format function from date-fns

const GSTReport = () => {
  const [orders, setOrders] = useState([]);
  const [filters, setFilters] = useState({
    fromDate: "",
    toDate: "",
  });

  const columns = [
    {
      field: "date",
      headerName: "Date",
      width: 150,
      valueFormatter: (params) => format(new Date(params.value), "dd/LL/yyyy"),
    },
    { field: "customername", headerName: "Customer Name", width: 200 },
    { field: "id", headerName: "Order Number", width: 150 },
    {
      field: "baseAmount",
      headerName: "Total Amount (Without GST)",
      width: 200,
      valueFormatter: (params) => `₹${params.value.toFixed(2)}`,
    },
    {
      field: "taxAmount",
      headerName: "Tax Amount",
      width: 150,
      valueFormatter: (params) => `₹${params.value.toFixed(2)}`,
    },
    {
      field: "totalAmount",
      headerName: "Total Amount (With GST)",
      width: 200,
      valueFormatter: (params) => `₹${params.value.toFixed(2)}`,
    },
  ];

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [ordersSnapshot, subProductsSnapshot] = await Promise.all([
          getDocs(collection(db, "orders")),
          getDocs(collection(db, "sub-product")),
        ]);

        // Create subProducts lookup map
        const subProducts = {};
        subProductsSnapshot.docs.forEach((doc) => {
          subProducts[doc.id] = doc.data();
        });

        // Process orders
        let processedOrders = ordersSnapshot.docs.map((doc) => {
          const orderData = doc.data();

          // Calculate totals
          const totals = orderData.products.reduce(
            (acc, product) => {
              const subProduct = subProducts[product.subproductId] || {};
              const quantity = product.quantity || 0;
              const price = subProduct.sellCost || 0;
              const gstRate = subProduct.gst || 0;

              const baseAmount = quantity * price;
              const taxAmount = (baseAmount * gstRate) / 100;

              return {
                baseAmount: acc.baseAmount + baseAmount,
                taxAmount: acc.taxAmount + taxAmount,
              };
            },
            { baseAmount: 0, taxAmount: 0 }
          );

          return {
            id: doc.id,
            customername: orderData.customername,
            baseAmount: totals.baseAmount,
            taxAmount: totals.taxAmount,
            totalAmount: totals.baseAmount + totals.taxAmount,
            date: orderData.date?.toDate(),
          };
        });

        // Apply date filters
        if (filters.fromDate || filters.toDate) {
          processedOrders = processedOrders.filter((order) => {
            const orderDate = order.date;
            const fromDate = filters.fromDate
              ? new Date(filters.fromDate)
              : null;
            const toDate = filters.toDate ? new Date(filters.toDate) : null;

            return (
              (!fromDate || orderDate >= fromDate) &&
              (!toDate || orderDate <= toDate)
            );
          });
        }

        setOrders(processedOrders);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [filters]);

  const exportToExcel = () => {
    const csvContent = [
      "Date,Customer Name,Order Number,Total Amount (Without GST),Tax Amount,Total Amount (With GST)",
      ...orders.map(
        (order) =>
          `${format(order.date, "dd/LL/yyyy")},${order.customername},${
            order.id
          },₹${order.baseAmount.toFixed(2)},₹${order.taxAmount.toFixed(
            2
          )},₹${order.totalAmount.toFixed(2)}`
      ),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "gst_report.csv";
    link.click();
  };

  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <h2 align="center" style={{ margin: "0 0 20px 0" }}>
        GST Report
      </h2>

      <Paper sx={{ p: 2, mb: 2 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              type="date"
              name="fromDate"
              label="From Date"
              value={filters.fromDate}
              onChange={handleFilterChange}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              type="date"
              name="toDate"
              label="To Date"
              value={filters.toDate}
              onChange={handleFilterChange}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              variant="contained"
              onClick={exportToExcel}
              style={{ backgroundColor: "#027f89" }}
            >
              Export to Excel
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <ListInTable
        rows={orders}
        columns={columns}
        height={500}
        columnWidth={200}
      />
    </div>
  );
};

export default GSTReport;
