// Product data list
export const unitListTableColumns = [
  // { field: "id", headerName: "ID", width: 100 },
  // {
  //   field: "productImg",
  //   headerName: "Image",
  //   width: 100,
  //   renderCell: (params) => {
  //     return (
  //       <div className="cell_img_div">
  //         <img className="cell_img" src={params.row.productImg} alt="avatar" />
  //       </div>
  //     );
  //   },
  // },
  {
    field: "shopname",
    headerName: "Shop Name",
    width: 150,
  },
  {
    field: "ownername",
    headerName: "Owner Name",
    width: 100,
  },
  {
    field: "type",
    headerName: "type",
    width: 70,
  },

  {
    field: "gst",
    headerName: "GST",
    width: 70,
  },
  {
    field: "email",
    headerName: "email",
    width: 110,
  },
  {
    field: "contact",
    headerName: "contact",
    width: 100,
  },
  {
    field: "acontact",
    headerName: "Alternate contact",
    width: 100,
  },
  {
    field: "cat",
    headerName: "category",
    width: 70,
  },
  {
    field: "address",
    headerName: "address",
    width: 70,
  },
  {
    field: "pincode",
    headerName: "pincode",
    width: 70,
  },
  {
    field: "dob",
    headerName: "DOB",
    width: 70,
  },
  {
    field: "shope",
    headerName: "Shop Established",
    width: 70,
  },
  {
    field: "plumberName",
    headerName: "plumber Name",
    width: 70,
  },
  {
    field: "plumberNumber",
    headerName: "plumber Number",
    width: 70,
  },
  {
    field: "electricianName",
    headerName: "electrician Name",
    width: 70,
  },
  {
    field: "electricianNumber",
    headerName: "electrician Number",
    width: 70,
  },
  {
    field: "carpenterName",
    headerName: "carpenter Name",
    width: 70,
  },
  {
    field: "carpenterNumber",
    headerName: "carpenter Number",
    width: 70,
  },


];

export const unitListTableRows = [
  {
    id: 1,
    productName: "Acer nitro 5",
    productImg:
      "https://m.media-amazon.com/images/I/81bc8mA3nKL._AC_UY327_FMwebp_QL65_.jpg",
    price: "$985",
    brand: "ACER",
    model: "Nitro 5",
    quantity: "30",
  },
  {
    id: 2,
    productName: "Redragon S101",
    productImg:
      "https://m.media-amazon.com/images/I/71kr3WAj1FL._AC_UY327_FMwebp_QL65_.jpg",
    price: "$65",
    brand: "Unknown",
    model: "VIP 5",
    quantity: "25",
  },
  {
    id: 3,
    productName: "Razer Blade 15",
    productImg:
      "https://m.media-amazon.com/images/I/71wF7YDIQkL._AC_UY327_FMwebp_QL65_.jpg",
    price: "$435",
    brand: "VIP 5",
    model: "5Loh",
    quantity: "57",
  },
];
