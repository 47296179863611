// SalesData.js
import { collection, getDocs } from "firebase/firestore";
import db from "../../firebase";

export const fetchSalesData = async () => {
  const ordersQuerySnapshot = await getDocs(collection(db, "orders"));
  const subproductsQuerySnapshot = await getDocs(collection(db, "subproducts"));
  const subproducts = subproductsQuerySnapshot.docs.map((doc) => doc.data());

  const rowsData = ordersQuerySnapshot.docs.map((doc) => doc.data());
  rowsData.forEach((order) => {
    order.date = order.date.toDate().toLocaleDateString("en-GB");
    order.total = order.total || 0;

    order.products.forEach((sub) => {
      const subproduct = subproducts.find((a) => a.id === sub.subproductId);
      if (subproduct) {
        order.total += sub.quantity * subproduct.sellCost;
      }
    });
  });

  return rowsData;
};

export const salesListTableColumns = [
  {
    field: "id",
    headerName: "Order ID",
    width: 120,
  },
  {
    field: "customer",
    headerName: "Customer",
    width: 150,
  },
  {
    field: "staffId",
    headerName: "Staff ID",
    width: 120,
  },
  {
    field: "date",
    headerName: "Date",
    width: 120,
  },
  {
    field: "paymentMethod",
    headerName: "Payment Method",
    width: 150,
  },
  {
    field: "status",
    headerName: "Status",
    width: 120,
    renderCell: (params) => (
      <div className={`status ${params.value.toLowerCase()}`}>
        {params.value}
      </div>
    ),
  },
  {
    field: "total",
    headerName: "Total Amount",
    width: 130,
    renderCell: (params) => `₹${params.value}`,
  },
];
