// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDXAtYzO_9wf3eEwG1Wp4t6h0WSWb8S9uw",
  authDomain: "paints-79f67.firebaseapp.com",
  projectId: "paints-79f67",
  storageBucket: "paints-79f67.appspot.com",
  messagingSenderId: "528922411346",
  appId: "1:528922411346:web:f0f8c05b5e0a4209be7a8b",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const db = getFirestore(app);
export default db;
export { auth };
