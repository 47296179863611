import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { LoaderContext } from "../../App";
import { motion } from "framer-motion";
import Loader from "../../Reusable Components/Loader";
import "./Login.sass";
import { collection, query, where, getDocs } from "firebase/firestore";
import db from "../../firebase";

const Login = (props) => {
  const { isLoading } = useContext(LoaderContext);
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [loginStatus, setLoginStatus] = useState(""); // Added state to track login status

  const handleUserNameChange = (e) => {
    setUserName(e.target.value);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = async () => {
    setLoginStatus(""); // Reset login status on each login attempt
    const q = query(collection(db, "staff"), where("email", "==", userName));
    const querySnapshot = await getDocs(q);
    let userFound = false; // Flag to check if user is found
    querySnapshot.forEach((doc) => {
      if (doc.data().password === password) {
        if (doc.data().status === "Inactive") {
          setLoginStatus("User is Inactive, contact admin");
          return;
        } else {
          userFound = true; // User found and password matches
          localStorage.setItem("userName", userName);
          navigate("/home");
          setLoginStatus("Login success");
        }
      }
    });
    if (!userFound) {
      // If user is not found or password does not match
      setLoginStatus("Password or email is incorrect"); // Set login status to password incorrect
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div className="login_page">
              <h5>Login to Admin Dashboard</h5>
              <div className="username_container">
                <label htmlFor="username">Email</label>
                <input
                  type="text"
                  id="username"
                  value={userName}
                  onChange={handleUserNameChange}
                  placeholder="Email"
                />
              </div>
              <div className="password_container">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={handlePassword}
                  placeholder="Password"
                />
              </div>
              <button onClick={handleLogin}>Login</button>
              {loginStatus && (
                <p
                  style={{
                    color: loginStatus === "Login success" ? "green" : "red",
                    margin: "24px 0 0 0",
                  }}
                >
                  {loginStatus}
                </p>
              )}
              {/* Show login status message if login fails or succeeds */}
            </div>
          </motion.div>
        </>
      )}
    </>
  );
};

export default Login;
