import React, { useState, useEffect } from "react";
import { TextField, Grid, MenuItem, Paper } from "@mui/material";
import { collection, getDocs } from "firebase/firestore";
import db from "../../firebase";
import ListInTable from "../../Reusable Components/DataTable";
import { format } from "date-fns";

const GeneralReport = () => {
  const [orders, setOrders] = useState([]);
  const [products, setProducts] = useState([]);
  const [filters, setFilters] = useState({
    fromDate: "",
    toDate: "",
    product: "",
    invoiceNumber: "",
    customerName: "",
    customerEmail: "",
    customerContact: "",
  });

  const columns = [
    { field: "date", headerName: "Date", width: 150 },
    { field: "productName", headerName: "Product", width: 200 },
    { field: "id", headerName: "Invoice Number", width: 150 },
    { field: "customername", headerName: "Customer Name", width: 200 },
    { field: "customeremail", headerName: "Customer Email", width: 200 },
    { field: "customercontact", headerName: "Customer Contact", width: 150 },
  ];

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSearch = () => {
    const fetchData = async () => {
      try {
        // Fetch orders and products
        const [ordersSnapshot, mainProductsSnapshot] = await Promise.all([
          getDocs(collection(db, "orders")),
          getDocs(collection(db, "main-product")),
        ]);

        // Process products for dropdown
        const mainProducts = mainProductsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProducts(mainProducts);

        // Process orders
        let ordersData = ordersSnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            date: format(data.date.toDate(), "dd/MM/yyyy"), // Format the date here
            productName: data.products
              ? data.products.map((p) => p.name).join(", ") // Ensure product names are joined
              : "",
            customeremail: data.customeremail || "", // Ensure customer email is included
            customercontact: data.customercontact || "", // Ensure customer contact is included
          };
        });

        // Apply filters
        ordersData = ordersData.filter((order) => {
          const orderDate = order.date ? new Date(order.date) : null;
          const fromDate = filters.fromDate ? new Date(filters.fromDate) : null;
          const toDate = filters.toDate ? new Date(filters.toDate) : null;

          return (
            (!fromDate || (orderDate && orderDate >= fromDate)) &&
            (!toDate || (orderDate && orderDate <= toDate)) &&
            (!filters.product ||
              order.productName
                .toLowerCase()
                .includes(filters.product.toLowerCase())) &&
            (!filters.invoiceNumber ||
              order.id
                .toLowerCase()
                .includes(filters.invoiceNumber.toLowerCase())) &&
            (!filters.customerName ||
              order.customername
                ?.toLowerCase()
                .includes(filters.customerName.toLowerCase())) &&
            (!filters.customerEmail ||
              order.customeremail
                ?.toLowerCase()
                .includes(filters.customerEmail.toLowerCase())) &&
            (!filters.customerContact ||
              order.customercontact?.includes(filters.customerContact))
          );
        });

        setOrders(ordersData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  };

  useEffect(() => {
    handleSearch();
  }, [filters]);

  const renderFilters = () => (
    <Paper sx={{ p: 2, mb: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            fullWidth
            type="date"
            name="fromDate"
            label="From Date"
            value={filters.fromDate}
            onChange={handleFilterChange}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            fullWidth
            type="date"
            name="toDate"
            label="To Date"
            value={filters.toDate}
            onChange={handleFilterChange}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            fullWidth
            select
            name="product"
            label="Product"
            value={filters.product}
            onChange={handleFilterChange}
          >
            <MenuItem value="">All Products</MenuItem>
            {products.map((product) => (
              <MenuItem key={product.id} value={product.productName}>
                {product.productName}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            fullWidth
            name="invoiceNumber"
            label="Invoice Number"
            value={filters.invoiceNumber}
            onChange={handleFilterChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            fullWidth
            name="customerName"
            label="Customer Name"
            value={filters.customerName}
            onChange={handleFilterChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            fullWidth
            name="customerEmail"
            label="Customer Email"
            value={filters.customerEmail}
            onChange={handleFilterChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            fullWidth
            name="customerContact"
            label="Customer Contact"
            value={filters.customerContact}
            onChange={handleFilterChange}
          />
        </Grid>
      </Grid>
    </Paper>
  );

  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
      }}
    >
      <h2 align="center">General Report</h2>
      {renderFilters()}
      <ListInTable rows={orders} columns={columns} height={400} />
    </div>
  );
};

export default GeneralReport;
