import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Button,
  TextField,
  IconButton,
  Card,
  CardMedia,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import db from "../../firebase";
import uploadBannerImage from "../../uploadBannerImage";
import { format } from "date-fns";
import Navbar from "../../Components/Navbar/Navbar";

const Banner = () => {
  const [banners, setBanners] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [bannerName, setBannerName] = useState("");
  const [editBannerId, setEditBannerId] = useState(null);

  // Fetch banners
  const fetchBanners = async () => {
    try {
      const bannersSnapshot = await getDocs(collection(db, "banners"));
      const bannersData = bannersSnapshot.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .filter((banner) => banner.status !== false);
      setBanners(bannersData);
    } catch (error) {
      console.error("Error fetching banners:", error);
      alert("Error loading banners. Please refresh the page.");
    }
  };

  useEffect(() => {
    fetchBanners();
  }, []);

  // Handle file selection
  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  // Handle banner upload
  const handleUpload = async () => {
    if (!selectedFile || !bannerName) return;

    try {
      const bannerId =
        editBannerId || bannerName.toLowerCase().replace(/\s+/g, "-");
      await uploadBannerImage(selectedFile, bannerId);
      await fetchBanners();
      setOpenDialog(false);
      setSelectedFile(null);
      setBannerName("");
      setEditBannerId(null);
    } catch (error) {
      console.error("Error uploading banner:", error);
      alert("Failed to upload banner. Please try again.");
    }
  };

  // Handle banner deletion
  const handleDelete = async (bannerId) => {
    if (window.confirm("Are you sure you want to delete this banner?")) {
      try {
        await deleteDoc(doc(db, "banners", bannerId));
        fetchBanners();
      } catch (error) {
        console.error("Error deleting banner:", error);
      }
    }
  };

  // Handle edit
  const handleEdit = (banner) => {
    setBannerName(banner.name);
    setEditBannerId(banner.id);
    setOpenDialog(true);
  };

  return (
    <>
      <Navbar />
      <div style={{ padding: "20px" }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h2 style={{ margin: 0 }}>Banners</h2>
              <Button
                variant="contained"
                onClick={() => setOpenDialog(true)}
                style={{ backgroundColor: "#027f89" }}
              >
                Add New Banner
              </Button>
            </Paper>
          </Grid>

          {/* Banner Grid */}
          {banners.map((banner) => (
            <Grid item xs={12} sm={6} md={4} key={banner.id}>
              <Card>
                <CardMedia
                  component="img"
                  height="200"
                  image={banner.image_url}
                  alt={banner.name}
                  style={{ objectFit: "cover" }}
                />
                <CardActions sx={{ justifyContent: "space-between", p: 2 }}>
                  <div>
                    <h4 style={{ margin: 0 }}>{banner.name}</h4>
                    <small>
                      Updated:{" "}
                      {banner.updated_at
                        ? banner.updated_at?.toDate
                          ? format(banner.updated_at.toDate(), "dd/MM/yyyy")
                          : format(
                              new Date(banner.updated_at.seconds * 1000),
                              "dd/MM/yyyy"
                            )
                        : format(new Date(), "dd/MM/yyyy")}
                    </small>
                  </div>
                  <div>
                    <IconButton
                      onClick={() => handleEdit(banner)}
                      color="primary"
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      onClick={() => handleDelete(banner.id)}
                      color="error"
                    >
                      <Delete />
                    </IconButton>
                  </div>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* Upload Dialog */}
        <Dialog
          open={openDialog}
          onClose={() => {
            setOpenDialog(false);
            setBannerName("");
            setSelectedFile(null);
            setEditBannerId(null);
          }}
        >
          <DialogTitle>
            {editBannerId ? "Edit Banner" : "Add New Banner"}
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Banner Name"
              fullWidth
              variant="outlined"
              value={bannerName}
              onChange={(e) => setBannerName(e.target.value)}
              sx={{ mb: 2 }}
            />
            <input
              accept="image/*"
              type="file"
              onChange={handleFileSelect}
              style={{ marginBottom: "20px" }}
            />
            {selectedFile && (
              <div style={{ marginTop: "10px" }}>
                Selected file: {selectedFile.name}
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpenDialog(false);
                setBannerName("");
                setSelectedFile(null);
                setEditBannerId(null);
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleUpload}
              variant="contained"
              style={{ backgroundColor: "#027f89" }}
              disabled={!selectedFile || !bannerName}
            >
              {editBannerId ? "Update" : "Upload"}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default Banner;
