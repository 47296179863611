import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { ThemeContext, ProfileContext } from "../../App";
import MenuIcon from "@mui/icons-material/Menu";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import CloseIcon from "@mui/icons-material/Close";
import "./Navbar.sass";

const Navbar = () => {
  const { handleDarkMode } = useContext(ThemeContext);
  const { userName, profilePic } = useContext(ProfileContext);
  const [menu, setMenu] = useState(false);
  const [profileMenu, setProfileMenu] = useState(false);
  const [profile, setProfile] = useState(false);
  const [popup, setPopup] = useState(false);
  const location = useLocation();

  function handleMenu() {
    setMenu(!menu);
    document
      .querySelector(".dashboard_container_left_panel")
      .setAttribute("style", `${menu ? "" : "display: block"}`);
  }

  const adminProfileDiv = document.querySelector(".admin_profile_div");
  function handleProfileMenu() {
    const isProfileMenuOpen = profileMenu;
    setProfileMenu(!isProfileMenuOpen);
    document
      .querySelector(".items_div")
      .setAttribute(
        "style",
        `${isProfileMenuOpen ? "" : "display: block; opacity: 1"}`
      );
    if (isProfileMenuOpen && profile) {
      setProfile(false);
      adminProfileDiv.setAttribute("style", "display: none");
    }
  }

  function handleProfile() {
    setProfile(!profile);
    adminProfileDiv.setAttribute(
      "style",
      `display: ${profile ? "none" : "block"}`
    );
  }

  useEffect(() => {
    function handlePopup() {
      setPopup(true);
      const popupDiv = document.querySelector(".welcome_popup_div");
      if (popupDiv) {
        popupDiv.style.display = "block";
      }
      localStorage.setItem("popupShown", true);
    }

    const popupShown = localStorage.getItem("popupShown");
    if (!popupShown) {
      handlePopup();
    }
  }, []);

  function removePopup() {
    setPopup(false);
    localStorage.setItem("popupShown", true);
  }

  const shouldShowBackButton = () => {
    return location.pathname !== "/home";
  };

  return (
    <>
      <div
        className="navbar_container"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="navbar_logo_div">
          {menu ? (
            <CloseIcon
              className="hamburger_menu_btn bg-danger text-light p-1"
              onClick={handleMenu}
            />
          ) : (
            <MenuIcon className="hamburger_menu_btn" onClick={handleMenu} />
          )}
          {shouldShowBackButton() && (
            <button
              className="back_button"
              style={{
                padding: "10px 20px",
                borderRadius: "8px",
                border: "none",
                cursor: "pointer",
                backgroundColor: "#027f89",
                color: "#fff",
                margin: "12px 12px 12px 12px",
                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
                transition: "all 0.2s ease-in-out",
                fontSize: "14px",
                fontWeight: "500",
                display: "flex",
                alignItems: "center",
                gap: "6px",
                "&:hover": {
                  backgroundColor: "#015c63",
                  transform: "translateY(-1px)",
                  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
                },
                "&:active": {
                  transform: "translateY(1px)",
                  boxShadow: "0 1px 4px rgba(0, 0, 0, 0.1)",
                },
              }}
              onClick={() => window.history.back()}
            >
              <span style={{ fontSize: "18px" }}>←</span> Back
            </button>
          )}
          <Link to="/home" style={{ textDecoration: "none", color: "unset" }}>
            <span className="logo" style={{ fontSize: "20px" }}>
              Admin Dashboard
            </span>
          </Link>
        </div>
        <div className="search_and_settings_div">
          <div className="items_div_wrapper">
            <div className="items_div">
              <div className="item" onClick={handleProfile}>
                <AccountCircleOutlinedIcon className="icon" />
                <p className="mb-0 mx-1">Profile</p>
              </div>
              <div className="item" onClick={handleDarkMode}>
                <DarkModeOutlinedIcon className="icon" />
                <p className="mb-0 mx-1">Toggle Theme</p>
              </div>
            </div>
            {popup && (
              <div className="welcome_popup_div">
                <p className="welcome_text mb-0">
                  Welcome <span>{userName}</span> to the Admin Dashboard. Feel
                  free to explore and let me know if you have any questions.
                  Thanks for joining!
                </p>
                <CloseIcon className="icon" onClick={removePopup} />
              </div>
            )}
            <div
              className="user_profile_div d-flex align-items-center justify-content-center"
              onClick={handleProfileMenu}
            >
              <p
                className="username mb-0"
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  margin: "0 20px 0px 0px",
                }}
              >
                {userName}
              </p>
              <img src={profilePic} alt="Dashboard user" className="avatar" />
            </div>
          </div>
          <div className="admin_profile_div">
            <div className="admin_info_div">
              <div className="admin_name_and_img_div">
                <p className="mb-1">
                  <span>Admin:</span> {userName}
                </p>
                <img
                  src={profilePic}
                  alt="Admin Avatar"
                  width="20px"
                  height="20px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
