import React, { useState, useEffect, useRef } from "react";
import {
  TableCell,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from "@mui/material";

import { useTheme } from "@mui/material/styles";
import Navbar from "../../Components/Navbar/Navbar";
import Sidebar from "../../Components/Sidebar/Sidebar";
import "../../Reusable Styling/Table.sass";
import { collection, getDocs } from "firebase/firestore";
import db from "../../firebase";

import format from "date-fns/format";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ListInTable from "../../Reusable Components/DataTable";
import { salesListTableColumns } from "./SalesData";

const Sales = () => {
  const theme = useTheme();
  const [totalBalance, setTotalBalance] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalPendingOrder, setTotalPendingOrder] = useState(0);
  const [totalDeliveredOrder, setTotalDeliveredOrder] = useState(0);
  const [totalCashPaymentOrder, setTotalCashPaymentOrder] = useState(0);
  const [totalOnlinePaymentOrder, setTotalOnlinePaymentOrder] = useState(0);
  const [totalPaidAmount, setTotalPaidAmount] = useState(0);
  const [totalPayableAmount, setTotalPayableAmount] = useState(0);
  const [totalPendingAmount, setTotalPendingAmount] = useState(0);
  const totalsalesRef = useRef(0);
  const [rows, setRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const getSubproducts = async () => {
    const subproductsQuerySnapshot = await getDocs(
      collection(db, "subproducts")
    );
    return subproductsQuerySnapshot.docs.map((doc) => doc.data());
  };

  const fetchData = async () => {
    try {
      const ordersQuerySnapshot = await getDocs(collection(db, "orders"));
      const subproducts = await getSubproducts();
      const rowsData = ordersQuerySnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          ...data,
          id: data.id,
          date: format(data.date.toDate(), "dd/MM/yyyy"),
          customer: data.customername,
          staffId: data.staffid,
          paymentMethod: data.paymentmethod,
          total:
            data.total ||
            data.products.reduce((sum, product) => {
              const subproduct = subproducts.find(
                (s) => s.id === product.subproductId
              );
              return (
                sum + (subproduct ? product.quantity * subproduct.sellCost : 0)
              );
            }, 0),
        };
      });

      let filteredRows = rowsData;

      if (searchTerm) {
        filteredRows = rowsData.filter(
          (order) =>
            order.customer?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            order.contact?.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }

      if (startDate && endDate) {
        filteredRows = filteredRows.filter((order) => {
          const orderDate = new Date(order.date.split("/").reverse().join("-"));
          return orderDate >= startDate && orderDate <= endDate;
        });
      }

      const totals = calculateTotals(filteredRows);
      updateTotalStates(totals);

      setRows(filteredRows);
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };

  const calculateTotals = (rows) => {
    return rows.reduce(
      (acc, order) => ({
        totalBalance: acc.totalBalance + parseFloat(order.total),
        totalQuantity: acc.totalQuantity + order.products.length,
        totalPendingOrder:
          acc.totalPendingOrder + (order.status === "pending" ? 1 : 0),
        totalDeliveredOrder:
          acc.totalDeliveredOrder + (order.status === "delivered" ? 1 : 0),
        totalCashPaymentOrder:
          acc.totalCashPaymentOrder + (order.paymentmethod === "cash" ? 1 : 0),
        totalOnlinePaymentOrder:
          acc.totalOnlinePaymentOrder +
          (order.paymentmethod === "online" ? 1 : 0),
        totalPaidAmount:
          acc.totalPaidAmount +
          (order.status === "delivered" ? parseFloat(order.total) : 0),
        totalPendingAmount:
          acc.totalPendingAmount +
          (order.status === "pending" ? parseFloat(order.total) : 0),
      }),
      {
        totalBalance: 0,
        totalQuantity: 0,
        totalPendingOrder: 0,
        totalDeliveredOrder: 0,
        totalCashPaymentOrder: 0,
        totalOnlinePaymentOrder: 0,
        totalPaidAmount: 0,
        totalPendingAmount: 0,
      }
    );
  };

  const updateTotalStates = (totals) => {
    setTotalBalance(totals.totalBalance);
    setTotalQuantity(totals.totalQuantity);
    setTotalPendingOrder(totals.totalPendingOrder);
    setTotalDeliveredOrder(totals.totalDeliveredOrder);
    setTotalCashPaymentOrder(totals.totalCashPaymentOrder);
    setTotalOnlinePaymentOrder(totals.totalOnlinePaymentOrder);
    setTotalPaidAmount(totals.totalPaidAmount);
    setTotalPendingAmount(totals.totalPendingAmount);
    setTotalPayableAmount(totals.totalPendingAmount - totals.totalPaidAmount);
    totalsalesRef.current = rows.length;
  };

  const handleSearch = () => {
    fetchData(); // This will trigger the search with current searchTerm
  };

  const handleFilter = () => {
    fetchData(); // This will trigger the date filter
  };

  const handleExportToCSV = () => {
    const csvContent =
      Object.keys(rows[0]).join(",") +
      "\n" +
      rows.map((row) => Object.values(row).join(",")).join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "sales_data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    document.title = "All Orders | Admin Dashboard";
    fetchData();
  }, []); // Remove searchTerm and dates from dependency array since we'll use buttons

  const handleRowClick = (id) => {
    // setSelectedRowId(id);
  };

  // Add this constant for summary table headers
  const summaryTableHeaders = [
    "Total Balance",
    "Total Sales",
    "Total Quantity",
    "Total Delivered",
    "Total Pending",
    "Cash Payment",
    "Online Payment",
    "Total Paid Amount",
    "Total Payable Amount",
    "Total Pending Amount",
  ];

  return (
    <main className="dashboard_container_main">
      <Sidebar />
      <div className="dashboard_container_right_panel">
        <Navbar />

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "1rem",
            gap: "1rem",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              width: "50%",
            }}
          >
            <TextField
              label="Search by Customer Name or Contact"
              variant="outlined"
              fullWidth
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={{ ml: 1 }}
            />
            <Button
              variant="contained"
              onClick={handleSearch}
              sx={{ fontSize: "16px", whiteSpace: "nowrap" }}
            >
              Search
            </Button>
          </div>

          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Start Date"
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
              <DatePicker
                label="End Date"
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <Button
              variant="contained"
              onClick={handleFilter}
              sx={{ fontSize: "16px" }}
            >
              Filter
            </Button>
          </div>

          <Button
            variant="contained"
            onClick={handleExportToCSV}
            sx={{
              fontSize: "16px",
              whiteSpace: "nowrap",
              paddingTop: "4px",
              paddingBottom: "4px",
              marginTop: "4px",
              marginBottom: "4px",
            }}
          >
            Export
          </Button>
        </div>

        <div style={{ margin: "1rem" }}>
          <Table>
            <TableHead>
              <TableRow>
                {summaryTableHeaders.map((header, i) => (
                  <TableCell key={i} className="table_cell">
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{totalBalance}</TableCell>
                <TableCell>{totalsalesRef.current}</TableCell>
                <TableCell>{totalQuantity}</TableCell>
                <TableCell>{totalDeliveredOrder}</TableCell>
                <TableCell>{totalPendingOrder}</TableCell>
                <TableCell>{totalCashPaymentOrder}</TableCell>
                <TableCell>{totalOnlinePaymentOrder}</TableCell>
                <TableCell>{totalPaidAmount}</TableCell>
                <TableCell>{totalPayableAmount}</TableCell>
                <TableCell>{totalPendingAmount}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>

        <div style={{ height: 400, margin: "1rem" }}>
          <ListInTable
            rows={rows}
            columns={salesListTableColumns}
            height={400}
          />
        </div>
      </div>
    </main>
  );
};

export default Sales;
