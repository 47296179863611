export const helperColumns = [
  { field: "id", headerName: "ID", width: 100 },
  {
    field: "helper_name",
    headerName: "Helper Name",
    width: 200,
    renderCell: (params) => {
      return (
        <div className="cell_img_div">
          <img
            className="cell_img"
            src="https://sachinsamal005.netlify.app/img/sachin-samal.png"
            alt="avatar"
          />
          {params.row.helper_name}
        </div>
      );
    },
  },
  {
    field: "helper_contact",
    headerName: "Contact",
    width: 160,
  },
  {
    field: "helper_category",
    headerName: "Category",
    width: 150,
  },
  {
    field: "shop_details",
    headerName: "Shop Details",
    width: 200,
  },
];
