import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ProfileContext } from "../../App";
import Sidebar from "../../Components/Sidebar/Sidebar";
import Navbar from "../../Components/Navbar/Navbar";
import ListInTable from "../../Reusable Components/DataTable";
import { helperColumns } from "./HelpersData";
import {
  collection,
  query,
  getDocs,
  where,
  doc,
  deleteDoc,
} from "firebase/firestore";
import db from "../../firebase";
import toast from "react-hot-toast";

const Helpers = () => {
  const [rows, setRows] = useState([]);
  const { userName } = useContext(ProfileContext);

  function handleDelete(id) {
    try {
      const docRef = doc(db, "helpers", id);
      deleteDoc(docRef)
        .then(() => {
          toast.success("Helper deleted successfully");
          setRows(rows.filter((row) => row.id !== id));
        })
        .catch((error) => {
          console.error("Error deleting document:", error);
          toast.error("Failed to delete helper");
        });
    } catch (error) {
      console.error("Error in delete operation:", error);
      toast.error("Failed to delete helper");
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const q = query(collection(db, "helpers"));
        const querySnapshot = await getDocs(q);
        const rowsData = [];
        querySnapshot.forEach((doc) => {
          rowsData.push({
            ...doc.data(),
            id: doc.id,
          });
        });
        setRows(rowsData);
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, []);

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cell_action_div">
            <Link
              to={`/helpers/update/${params.row.id}`}
              style={{ textDecoration: "none", color: "unset" }}
              className="view_btn"
            >
              View
            </Link>
            <div
              className="delete_btn"
              onClick={() => handleDelete(params.row.id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    document.title = "Helpers | Admin Dashboard";
  });

  return (
    <main className="dashboard_container_main">
      <Sidebar />
      <div className="dashboard_container_right_panel">
        <Navbar />
        <HelperTable className="users_list_container">
          <div className="users_list_container_title">
            <h4 className="p-2 mb-0">Helpers handled by Admin | {userName}</h4>
          </div>
          <ListInTable
            rows={rows}
            columns={helperColumns.concat(actionColumn)}
            height={680}
          />
        </HelperTable>
      </div>
    </main>
  );
};

const HelperTable = styled.div`
  z-index: 0;
  p,
  div.MuiTablePagination-actions > button {
    color: inherit;
  }
`;

export default Helpers;
